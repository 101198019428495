<template>
  <div>
    <h1 class="h3">
      {{ $t('distributor.list_distributor') }}
    </h1>
    <a-row :gutter="24" class="mt-4">
      <a-col :span="12">
        <a-input-search
          v-model="params.q"
          size="large"
          :placeholder="$t('distributor.search')"
          :loading="isLoading"
          :disabled="isLoading"
          allow-clear
          @search="fetchDistributor"
        >
          <a-button slot="enterButton" class="bg-primary-blue text-white">
            {{ $t('utils.search') }} <a-icon type="search" />
          </a-button>
        </a-input-search>
      </a-col>
      <a-col :span="9" :offset="3" class="text-right">
        <a-button
          v-if="permission.includes('WRITE')"
          type="primary"
          size="large"
          ghost
        >
          {{ $t('distributor.export_data') }}
        </a-button>
        <a-button
          v-if="permission.includes('WRITE')"
          type="primary"
          size="large"
          class="ml-4"
          @click="toAddDistributor"
        >
          <a-icon type="plus-square" />
          {{ $t('distributor.add_distributor') }}
        </a-button>
      </a-col>
    </a-row>
    <div class="mt-4">
      <a-table
        class="distributor-list"
        :data-source="distributorList"
        :row-key="record => record.id"
        :columns="columns"
        :loading="isLoading"
        :pagination="false"
        :scroll="scroll"
      >
        <template slot="address" slot-scope="record">
          {{ record ? `${record.street}, ${record.city}` : ' ' }}
        </template>
        <template slot="status" slot-scope="text">
          <a-tag :color="colors[text]">
            {{ $t(`distributor.${text.toLowerCase()}`) }}
          </a-tag>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-dropdown :overlay-style="overlayStyle">
            <a @click.prevent="">
              <a-icon type="more" />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item key="1" class="py-2">
                  <a-icon type="eye" />
                  {{ $t('distributor.detail_distributor') }}
                </a-menu-item>
                <a-menu-item
                  v-if="permission.includes('WRITE')"
                  key="2"
                  class="py-2"
                  @click="onOpenModal(record.status)"
                >
                  <a-icon :type="record.status === 'ACTIVE' ? 'close-circle' : 'check-circle'" />
                  {{ $t(`distributor.${record.status.toLowerCase()}_condition`) }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
    </div>
    <div class="mt-4 text-right">
      <a-pagination
        v-model="params.page"
        :page-size="params.size"
        :page-size-options="sizeOptions"
        :total="totalRecord"
        :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
        :disabled="isLoading"
        show-size-changer
        @change="onPageChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>

    <a-modal
      v-model="visible"
      :width="400"
      :mask-closable="false"
      centered
      @cancel="onCloseModal"
    >
      <template slot="footer">
        <div class="mt-2 text-center">
          <a-button type="primary" class="mr-3" ghost @click="onCloseModal">
            {{ $t('utils.cancel') }}
          </a-button>
          <a-button type="primary" @click="onCloseModal">
            {{ $t('utils.yes') }}
          </a-button>
        </div>
      </template>
      <div class="text-center">
        <a-icon type="exclamation-circle" class="modal-warning" />
        <h5>{{ $t('distributor.deactivate_distributor', { value: $t(`distributor.${modalType}_condition`)}) }}</h5>
        <p class="mb-0">
          {{ $t('distributor.confirm_deactivate', { value: $t(`distributor.${modalType}_condition`)}) }}
        </p>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'ListDistributor',
}
</script>
<script setup>
import route from '@/router'
import { computed, getCurrentInstance, onMounted, reactive, ref, toRefs, watch } from 'vue'
import { getDistributor } from '@/api/channels/distributor'
import { columnsDistributor } from '@/data/columns'

const { $route, $router, $store } = getCurrentInstance().proxy.$root

const INITIAL_PARAMS = {
  q: '',
  level: 'distributor',
  // status: '',
  page: 1,
  size: 10,
  sort: 'sellerName,DESC',
}

// Data
const modalType = ref('')
const screenHeight = ref(((screen.height - 900) * 0.48) + (screen.height * 0.48))
const visible = ref(false)
const isLoading = ref(false)
const totalRecord = ref(0)
const distributorList = ref([])
const columns = ref(columnsDistributor)
const sizeOptions = ref(['10', '20', '30', '40', '50'])
const params = reactive(INITIAL_PARAMS)
const overlayStyle = reactive({ width: '180px' })
const colors = reactive({
  ACTIVE: 'blue',
  INACTIVE: 'red',
})

// Refs
const refParams = toRefs(params)

// Computed
const scroll = computed(() => ({ y: screenHeight.value > 430 ? screenHeight.value : 430 }))
const tHeight = computed(() => {
  return !distributorList.value.length ? '0px'
    : screenHeight.value > 430 ? `${screenHeight.value}px`
    : '430px'
})
const permission = computed(() => {
  const key = $route.meta.key.split(':')[0].slice(0, -1)
  return $store.getters['user/can'](key, 'reverse')
})

watch(() => route.app._route, (newValue) => {
    if (newValue.name === $route.name) {
      init()
    }
  })
watch(() => permission.value, 
  (newValue) => {
    if(!newValue.length) {
      $router.push({path: '/error/403', query: {...$route.query}})
    }
  })

// Method
const toAddDistributor = () => {
  $router.push({
    path: `/distributor-channel/${$route.params.id}/add-distributor`,
    query: { ...$route.query },
  })
}

const init = () => {
  refParams.value = INITIAL_PARAMS
  fetchDistributor()
}

const fetchDistributor = async () => {
  isLoading.value = true
  await getDistributor({
    headers: { 'Business-Id': $route.query.business_id },
    params,
  })
  .then(({ data: { data: response } }) => {
    distributorList.value = response?.records || []
    totalRecord.value = response?.total_record || 0
  })
  .catch(() => {
    distributorList.value = []
    totalRecord.value = 0
  })
  .finally(() => isLoading.value = false)
}

const onPageChange = (pageNumber) => {
  params.page = pageNumber
  fetchDistributor()
}

const onShowSizeChange = (current, pageSize) => {
  params.page = current
  params.size = pageSize
  fetchDistributor()
}

const onOpenModal = (type) => {
  modalType.value = type.toLowerCase()
  visible.value = true
}

const onCloseModal = () => {
  visible.value = false
}

// Mounted
onMounted(init)
</script>

<style lang="scss">
.modal-warning {
  margin-bottom: 20px;
  font-size: 60px;
  color: #F16F55;
}

.distributor-list div.ant-table-body {
  min-height: v-bind(tHeight);
}

.ant-table-row {
  background-color: #fff !important;

  &.ant-table-row-selected > td {
    background-color: #fff !important;
  }
}
</style>
